import React from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import Hero from "../components/panels/Hero";

// styles
import s from './media.module.scss'

// utils
import LinkButton from "../utils/controls/LinkButton";


export default function Videos({ 
  location,
  data: { strapiPnMediaContents },
}) {
  const { seo, hero } = strapiPnMediaContents;
  
  return (
    <>
      <SEO
        {...{
          title: `${hero[0].main_heading} | Insights`,
          description: seo.description
        }}
      />
      <Hero
        heading={hero[0].main_heading}
        icon={hero[0].about_hero_image.childImageSharp.fixed}
      />
      <div className={`container-fluid mt-4`}>
        <div className={`container`}>
        <div style={{ display: 'flex', marginBottom: '1.5rem'}}>
          <LinkButton 
                to={`/webinar`}
                backgroundColorCondition={hero[0].main_heading === "Webinars"}
                buttonStyles={s.sectionButton}
                innerText="Webinars"
              />
              <LinkButton 
                to={`/explainer-videos`}
                backgroundColorCondition={hero[0].main_heading === "Explainer Videos"}
                buttonStyles={s.sectionButton}
                innerText="Explainer Videos"
              />
              <LinkButton 
                to={`/media`}
                backgroundColorCondition={hero[0].main_heading === "Media"}
                buttonStyles={s.sectionButton}
                innerText="Media"
              />
          </div>
        </div>
      </div>
    </>
  );
}

export const mediaContents = graphql`
query mediaContents {
  strapiPnMediaContents {
    seo {
      description
    }
    hero {
      main_heading
      main_subheading
      about_hero_image: insight_image {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
}
`;